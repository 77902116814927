<template>
	<div v-if="formationProgress">
		<v-row
			class="fullAssistantContainer"
			:class="{
				palr5: $vuetify.breakpoint.smAndDown,
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
		>
			<div class="ortho-text-blue mb5 d-flex">
				<h3
					class="assistant-link ml-6"
					@click="showAssistant = true"
					:class="{
						menuSelected: showAssistant == true,
					}"
				>
					👇 Vérifie ton texte et personnalise ta formation
				</h3>
				<h3
					class="ortho-text-gray assistant-link ml-6 d-none"
					@click="showAssistant = false"
					:class="{
						menuSelected: showAssistant == false,
					}"
				>
					Mes enregistrements
				</h3>
			</div>
		</v-row>
		<v-row
			class="fullAssistantContainer mt20"
			:class="{
				palr5: $vuetify.breakpoint.smAndDown,
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
			v-if="showAssistant == true"
		>
			<v-col md="8" cols="12">
				<div class="container-textarea">
					<v-card class="shadow-blue-not-openned">
						<v-container fluid>
							<div
								contenteditable="true"
								class="divEditionText hide-scroll-bar fs14"
								rows="15"
								v-html="text"
								ref="text"
								style="overflow: scroll"
							>
								{{ text }}
							</div>
						</v-container>
						<p v-if="showConfirmation == true" class="mb-0 mr-4 ortho-text-green text-right">Texte enregistré !</p>
						<v-container
							fluid
							class="d-flex"
							:class="{
								'justify-end': checking.length != 0,
								'justify-end align-left': checking.length == 0,
							}"
						>
							<v-btn v-show="false" large depressed class="shades white black--text mr-1" @click="registerText()"
								>Enregistrer mon texte</v-btn
							>
							<star-rating
								class="mr-3"
								v-model="rating"
								:star-size="30"
								:show-rating="false"
								v-if="checking.length != 0 && showRating == true"
								@rating-selected="getRating()"
							/>
							<div v-if="showRating == false">
								<v-alert dense outlined type="success" color="#15c39a" class="mb-0 mr-1"
									>Ton avis a bien été envoyé, merci</v-alert
								>
							</div>
							<div class="actionBtnContainer">
								<v-btn
									v-if="formationProgress.user.entreprise_id.slug == 'demo' || btnExample"
									class="actionBtnAssistant mr-2"
									depressed
									large
									dark
									color="black"
									:loading="loading"
									@click="submitExample()"
									>Montre moi un exemple</v-btn
								>
								<v-btn
									class="actionBtnAssistant"
									depressed
									large
									dark
									color="#15c39a"
									:loading="loading"
									@click="submit()"
									>Vérifier</v-btn
								>
							</div>
						</v-container>
					</v-card>
				</div>
			</v-col>
			<v-col md="4" cols="12" :class="{ mt20: $vuetify.breakpoint.smAndDown }">
				<div v-if="checking.length != 0" class="ortho-text-blue assistant-container mb20">
					<img src="@/assets/icons/assistant.svg" class="assistant-icon" alt="" />
					<h2 class="ortho-text-gray">{{ checking.length }} suggestions</h2>
				</div>
				<div v-if="checking.length == 0" class="searching-image-container">
					<img src="@/assets/images/searching.svg" class="searching-image" alt="" />
				</div>
				<v-card v-else class="shadow-blue-not-openned mb20" v-for="(check, index) in checking" :key="index">
					<div class="suggestion-container" @mouseover="highlightError(index)" @mouseleave="initColorError(index)">
						<div class="align-items mb10">
							<v-icon class="iconError fs10" :class="check.issueType">fas fa-circle</v-icon>
							<span class="ml5 fs12 suggestion-topic"
								><strong v-if="check.issueType == 'typographical'">Typographie</strong>
								<strong v-else-if="check.issueType == 'misspelling'">Faute de frappe</strong>
								<strong v-else-if="check.issueType == 'style'">Style</strong>
								<strong v-else>Autre</strong>
							</span>
						</div>
						<div class="align-items ml15">
							<div v-if="check.replacements.length > 0" class="align-items container-chip row">
								<v-chip
									v-for="(remplacement, index) in limitArray(check.replacements)"
									:key="index"
									class="suggestion-chips"
									color="#15c39a"
									label
									>{{ remplacement.value }}</v-chip
								>
							</div>
							<p v-else><strong>Mot non reconnu</strong></p>
						</div>
						<div class="align-items ml15">
							<p>
								{{ check.message }}
							</p>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col md="8" cols="12">
				<v-alert v-if="error" type="error" class="mt20" prominent outlined>Une erreur s'est produite</v-alert>
			</v-col>
		</v-row>
		<v-row
			class="fullAssistantContainer mt20"
			:class="{
				palr5: $vuetify.breakpoint.smAndDown,
				palr10: $vuetify.breakpoint.mdAndUp,
			}"
			v-else
		>
			<TextsSaved :formationId="formationProgress.formation._id" />
		</v-row>
		<v-row justify="center">
			<v-dialog v-model="noError" persistent max-width="430">
				<v-card>
					<v-card-title class="headline"
						>Bravo {{ formationProgress.user.first_name }}, aucune erreur détectée 🎉</v-card-title
					>
					<v-card-text>Nous n'avons pas détecté d'erreur dans ton texte.</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="#15c39a" text @click="closeNoErrorModal()">OKAY</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</v-row>
		<ModalForRestrictedUser :testTrialData="formationProgress.formation.test_trial" modalName="assistantPage" />
		<v-snackbar v-model="snackbar" :center="true" color="#2dc7ae" :timeout="6000">
			<div class="d-flex justify-space-between align-center">
				<h3>Peronnalisation de ta formation avec tes erreurs</h3>
				<v-btn dark text @click="snackbar = false">Fermer</v-btn>
			</div>
		</v-snackbar>
	</div>
</template>

<script>
import StarRating from 'vue-star-rating';
import { mapGetters } from 'vuex';
import TextsSaved from './TextsSaved';
import ModalForRestrictedUser from '../other/ModalForRestrictedUser/ModalForRestrictedUser';
import moment from 'moment';

export default {
	name: 'Correcteur',
	data() {
		return {
			text: '',
			noError: false,
			checking: [],
			error: false,
			allTextError: [],
			rating: 0,
			showRating: null,
			showAssistant: true,
			showConfirmation: false,
			loading: false,
			snackbar: false,
			btnExample: true,
			storageKeySendExample: 'sendAssistantExample',
		};
	},
	components: {
		StarRating,
		TextsSaved,
		ModalForRestrictedUser,
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	mounted() {
		//check if cookie example btn is existing, if yes don't show btn
		const cookieSendExample = window.localStorage.getItem(this.storageKeySendExample);

		if (cookieSendExample == null) {
			this.btnExample = true;
		} else {
			return (this.btnExample = false);
		}
	},
	methods: {
		async submit() {
			//prevent double click error
			this.loading = true;

			//add cookie to remove example btn
			const newCookieAssistant = { hideUntil: moment().add(100, 'days').unix() };
			window.localStorage.setItem(this.storageKeySendExample, JSON.stringify(newCookieAssistant));

			try {
				this.checking = [];
				this.allTextError = [];
				let issueMessage = '';
				let textError = '';
				delete this.$http.defaults.headers.common['x-auth-token'];
				//Get value of div
				this.text = this.$refs.text.innerHTML;
				//Replace all html tags
				this.text = this.text.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');
				//Request to check errors
				const requestCheck = await this.$http.post('https://api.languagetoolplus.com/v2/check', null, {
					params: {
						text: this.text,
						language: 'fr',
						apiKey: '03351b55217c23e4',
						username: 'jade@orthographiq.com',
					},
				});
				//if no error pop message no error
				if (requestCheck.data.matches.length == 0) {
					this.noError = true;
					this.$confetti.start({
						particlesPerFrame: 0.04,
					});
				}
				requestCheck.data.matches.map((element) => {
					if (element.rule.issueType == 'misspelling') {
						issueMessage = element.rule.description;
					} else {
						issueMessage = element.message;
					}
					let positionInText = element.offset;
					textError = this.text.substring(positionInText, positionInText + element.context.length);
					this.allTextError.push({ text: textError, type: element.rule.issueType });
					let filterBestRemplacement = element.replacements.filter((remplacement) => {
						if (remplacement.value.length > 2) {
							if (remplacement.value.substring(0, 3) == textError.substring(0, 3)) {
								return remplacement;
							}
						} else {
							return remplacement;
						}
					});
					this.checking.push({
						issueType: element.rule.issueType,
						replacements: filterBestRemplacement,
						message: issueMessage,
					});
				});
				this.allTextError.map((element, index) => {
					this.text = this.text.replace(
						element.text,
						'<span class="errorText ' + element.type + '" id=textwrong-' + index + '>' + element.text + '</span>'
					);
				});
			} catch (error) {
				this.error = true;
			}
			this.loading = false;
			this.snackbar = true;
			this.showRating = true;
			this.btnExample = false;

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('Check text in assistant');
			}
		},

		limitArray(array) {
			return array.slice(0, 5);
		},
		highlightError(indexTextError) {
			document.getElementById('textwrong-' + indexTextError).className = 'highlight';
		},
		initColorError(indexTextError) {
			let typeError = this.allTextError[indexTextError].type;
			document.getElementById('textwrong-' + indexTextError).className = 'errorText ' + typeError;
		},
		async registerText() {
			const ratingAssistant = await this.$store.dispatch('assistant/postRating', {
				formationId: this.formationInfos._id,
				rating: this.rating,
				text: this.text,
			});
			if (ratingAssistant.status == 200) {
				this.showConfirmation = true;
			}
		},
		async getRating() {
			const ratingAssistant = await this.$store.dispatch('assistant/postRating', {
				formationId: this.formationInfos._id,
				rating: this.rating,
				text: this.text,
			});
			if (ratingAssistant.status == 200) {
				this.showRating = false;
				this.rating = 0;
			}
		},

		async submitExample() {
			//prevent double click error
			this.loading = true;

			//add cookie to remove example btn
			const newCookieAssistant = { hideUntil: moment().add(100, 'days').unix() };
			window.localStorage.setItem(this.storageKeySendExample, JSON.stringify(newCookieAssistant));

			var text =
				'Écrivez ou collez votre texte ici pour le faire vérifier en continue. Les erreurs seront soulignés de différentes couleurs : les erreurs d’orthografe en rouge et les erreurs grammaticaux en jaune. Les problèmes de style, comme par exemple les pléonasmes, seront marqués en bleu dans vos textes. Le saviez vous ? LanguageTool vous propose des synonymes lorsque vous double-cliquez sur un mot . Découvrez l’intégralité de ses fonctionnalités, parfoi inattendues, comme la vérification des date. Par exemple, le mercredi 28 août 2020 était en fait un vendredi !';

			try {
				this.checking = [];
				this.allTextError = [];
				let issueMessage = '';
				let textError = '';
				delete this.$http.defaults.headers.common['x-auth-token'];
				//Get value of div
				this.text = text;
				//Replace all html tags
				this.text = this.text.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ');
				//Request to check errors
				const requestCheck = await this.$http.post('https://api.languagetoolplus.com/v2/check', null, {
					params: {
						text: this.text,
						language: 'fr',
						apiKey: '03351b55217c23e4',
						username: 'jade@orthographiq.com',
					},
				});
				//if no error pop message no error
				if (requestCheck.data.matches.length == 0) {
					this.noError = true;
				}
				requestCheck.data.matches.map((element) => {
					if (element.rule.issueType == 'misspelling') {
						issueMessage = element.rule.description;
					} else {
						issueMessage = element.message;
					}
					let positionInText = element.offset;
					textError = this.text.substring(positionInText, positionInText + element.context.length);
					this.allTextError.push({ text: textError, type: element.rule.issueType });
					let filterBestRemplacement = element.replacements.filter((remplacement) => {
						if (remplacement.value.length > 2) {
							if (remplacement.value.substring(0, 3) == textError.substring(0, 3)) {
								return remplacement;
							}
						} else {
							return remplacement;
						}
					});
					this.checking.push({
						issueType: element.rule.issueType,
						replacements: filterBestRemplacement,
						message: issueMessage,
					});
				});
				this.allTextError.map((element, index) => {
					this.text = this.text.replace(
						element.text,
						'<span class="errorText ' + element.type + '" id=textwrong-' + index + '>' + element.text + '</span>'
					);
				});
			} catch (error) {
				this.error = true;
			}
			this.loading = false;
			this.btnExample = false;

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('Check text in assistant');
			}
		},
		closeNoErrorModal() {
			this.noError = false;
			this.$confetti.stop();
			this.snackbar = false;
		},
	},
};
</script>

<style lang="scss">
.fullAssistantContainer {
	margin-top: 20px;
}
.assistant-link {
	cursor: pointer;
	transition: color 0.5s;
}
.assistant-link:hover {
	color: black !important;
}
.searching-image-container {
	text-align: center;
}
.searching-image {
	max-width: 100%;
}
.container-textarea {
	position: -webkit-sticky;
	position: sticky;
	top: 100px;
	.divEditionText {
		height: 27rem;
		&:focus {
			outline: none;
		}
	}
}
.suggestion-topic {
	text-transform: uppercase;
}
.assistant-icon {
	width: 8%;
	margin-right: 10px;
}
.suggestion-container {
	padding: 20px 20px 20px 20px;
}
.suggestion-container:hover {
	background-color: rgba(255, 0, 0, 0.06);
}
.container-chip {
	margin-bottom: 1.5rem;
}
.suggestion-chips {
	margin-top: 0.5rem;
	cursor: pointer;
	color: white !important;
	font-weight: bold;
	margin-left: 12px;
}
.menuSelected {
	color: grey !important;
	// border-bottom: 1px solid grey;
}
.align-items {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.assistant-container {
	/* always write it like this for safari */
	display: flex;
	align-items: center;
	justify-content: left;
}
.v-input__slot {
	position: initial !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
	box-shadow: initial;
	-webkit-box-shadow: initial;
}
.errorText {
	&.uncategorized {
		background: rgb(201, 205, 255);
	}
	&.misspelling {
		background: rgb(255 228 201);
	}
	&.style {
		background: #e3c977;
	}
	&.typographical {
		background: rgba(255, 0, 0, 0.2);
	}
}
.iconError {
	&.uncategorized {
		color: rgb(201, 205, 255);
	}
	&.misspelling {
		color: rgb(255 228 201);
	}
	&.style {
		color: #e3c977;
	}
	&.typographical {
		color: rgba(255, 0, 0, 0.2);
	}
}
.highlight {
	background: rgba(255, 0, 0, 0.6);
}
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
	padding: initial;
}
.v-input {
	font-size: 20px !important;
}
@media screen and (max-width: 960px) {
	.fullAssistantContainer {
		margin-right: 0; // Override vuetify .row that makes page overflow y
		margin-left: 0;
	}
	.actionBtnContainer {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.actionBtnAssistant {
		width: 100%;
		margin-bottom: 10px;
	}
	.searching-image-container {
		display: none;
	}
}
</style>
